<div class="health-fund-container">
    <div class="health-text">
        <p>
            Az alábbiak segítségével gyorsan leellenőrizheti, hogy az Ön egészségpénztára szerepel-e szerződött partnereink között. Ha nem
            találja meg egészségpénztárát, ne keseredjen el! Csak egy telefonjába kerül, hogy rendszeresen bővülő listánk egy Ön által
            kívánt egészségpénztárral gyarapodjon!
        </p>
    </div>
    <div class="health-funds">
        <div class="health">
            <img src="./assets/images/healthfunds/allianz_logo.png" />
            <p>Allianz Hungária Egészségpénztár</p>
        </div>
        <div class="health">
            <img src="./assets/images/healthfunds/generali.jpg" />
            <p>Generali Önkéntes Kölcsönös Egészségpénztár</p>
        </div>
        <div class="health">
            <img src="./assets/images/healthfunds/OTP.jpg" />
            <p>OTP Országos Egészségpénztár</p>
        </div>
        <div class="health">
            <img src="./assets/images/healthfunds/premium_ep.jpg" />
            <p>Prémium Egészségpénztár</p>
        </div>
        <div class="health">
            <img src="./assets/images/healthfunds/uj_piller_ep.jpg" />
            <p>Új Pillér Egészségpénztár</p>
        </div>
        <div class="health">
            <img src="./assets/images/healthfunds/vasutas.jpg" />
            <p>Vasutas Egészségpénztár</p>
        </div>
        <div class="health">
            <img src="./assets/images/healthfunds/vit_egeszsegpenztar.jpg" />
            <p>Vitamin Egészségpénztár</p>
        </div>
        <div class="health">
            <img src="./assets/images/healthfunds/patika.gif" />
            <p>Patika Önkéntes Kölcsönös Egészségpénztár</p>
        </div>
        <div class="health">
            <img src="./assets/images/healthfunds/MKB.jpg" />
            <p>MBH Gondoskodás Egészségpénztár</p>
        </div>
        <div class="health">
            <img src="./assets/images/healthfunds/izys.png" />
            <p>IZYS Egészségpénztár</p>
        </div>
    </div>
</div>
